// -------------------------------------------
// Google Analytics
// -------------------------------------------

if (window.analyticsTrackingCode) {
    (function (i, s, o, g, r, a, m) {
        i['GoogleAnalyticsObject'] = r;
        i[r] = i[r] ||
            function () {
                (i[r].q = i[r].q || []).push(arguments);
            }, i[r].l = 1 * new Date();
        a = s.createElement(o),
            m = s.getElementsByTagName(o)[0];
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m);
    })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');

    // This is for the Earning Products Team. DO NOT Remove!
    ga('create', 'UA-37023508-5', 'auto');
    // Tracks the version number based on session
    var versionNumber = window.analyticsProgramVersion;
    ga('set', 'dimension1', versionNumber);

    var clientTracker = 'clientAccount'; //Change "clientAccount" to another name of your choice.
    var trackingCode = window.analyticsTrackingCode; //Tracking code, unique per program
    ga('create', trackingCode, 'auto', clientTracker); // Set the Client Analytics account number here.

    ga('send', 'pageview');
    ga(clientTracker + '.send', 'pageview');
}